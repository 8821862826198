<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 resources-screen gray-bg assign-resources"
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="resource-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: 'r_one-click-map-add-period-help-online'
        }"
      >
        <template v-slot:left>
          <router-link
            :to="{
              name: 'r_one-click-map-add-types'
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("one-click", "map-add", "set-map") }}
        </div>
        <template v-slot:right>
          <button @click="saveMap">
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>
    <div v-if="mapTypes.length">
      <ul class="clebex-item-section">
        <li class="clebex-item-section-item">
          <div class="clebex-pill-link">
            <div class="clebex-item-content-wrapper">
              <span class="label larger-label">
                <div>
                  <span v-for="(mapType, i) in mapTypes" :key="mapType.id"
                    >{{ i > 0 ? ", " : ""
                    }}<span v-if="mapType.translations">{{
                      mapType.translations.find(el => el.locale === locale)
                        ? mapType.translations.find(el => el.locale === locale)
                            .attributes.name
                        : mapType.name
                    }}</span></span
                  >
                </div>
              </span>
            </div>
          </div>
        </li>
        <li class="clebex-item-section-item no-bottom-border light">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label larger-label">
                <div v-if="selectedLevel">
                  {{ selectedLevel.name }},
                  {{ selectedLevel.full_path.join(", ") }}
                </div>
              </label>
            </div>
          </div>
        </li>
      </ul>

      <ul class="clebex-item-section overflow-hidden">
        <li
          class="clebex-item-section-item"
          :class="collapse.periods ? 'collapsed' : 'expanded'"
        >
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label larger-label">
                {{ displayLabelName("one-click", "map-add", "periods") }}
              </label>
            </div>
            <span
              class="follow-up-icons cursor-pointer"
              @click="
                collapse.periods
                  ? (collapse.periods = false)
                  : (collapse.periods = true)
              "
            >
              <span class="follow-up-icon-item collapsable"
                ><icon icon="#cx-app1-arrow-right-12x12" width="12" height="12"
              /></span>
            </span>
          </div>
        </li>
        <section :class="collapse.periods ? 'collapsed' : 'expanded ten'">
          <li
            v-for="period in periods"
            :key="period.id"
            class="clebex-item-section-item no-bottom-border light"
          >
            <div
              class="clebex-item-content-wrapper cursor-pointer"
              @click="periodId = period.id"
            >
              <div class="clebex-section-input">
                <label class="clebex-section-input-label cursor-pointer">{{
                  period.name
                }}</label>
              </div>
              <span v-if="periodId === period.id" class="follow-up-icons">
                <span class="follow-up-icon-item">
                  <div class="svg-icon">
                    <svg
                      width="12"
                      height="12"
                      style="width: 12px; height: 12px;"
                    >
                      <use xlink:href="#cx-app1-checkmark"></use>
                    </svg>
                  </div>
                </span>
              </span>
            </div>
          </li>
        </section>
      </ul>
      <div class="clebex-item-section">
        <select-slots :slots="slots"></select-slots>
      </div>
    </div>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import SelectSlots from "@/components/plans/SelectSlots";
import { mapActions, mapState } from "vuex";
import { DateTime } from "luxon";
import { getLang } from "@/services/http-service";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "OneClickMapAddResourcesMode",
  mixins: [helpOnlineMixin],
  created() {
    if (!this.mapTypes.length || !this.levels) {
      this.$router.push({
        name: "r_one-click-map-add-types"
      });
    }
    this.getPeriods();
    this.getSlots({
      payload: {
        types: ["DAY", "HALF_DAY", "HOUR", "HALF_HOUR", "QUARTER_HOUR"],
        type: "default",
        date: DateTime.now()
          .plus({ days: 1 })
          .toFormat("yyyy-MM-dd"),
        timezone: DateTime.now().zoneName
      },
      noLoader: false
    });
  },
  data() {
    return {
      periodId: 1,
      collapse: {},
      helpSlug: "one-click-settings-map-add-period"
    };
  },
  computed: {
    ...mapState("oneClick", ["periods", "maps"]),
    ...mapState("plan", ["slots", "selectedSlot"]),
    ...mapState("oneClick", ["mapTypes"]),
    ...mapState("level", ["levels"]),
    locale() {
      return getLang();
    },
    selectedLevel() {
      return this.levels.data.find(
        el => el.id === Number(this.$route.params.levelId)
      );
    }
  },
  methods: {
    ...mapActions("oneClick", ["getPeriods", "createMap"]),
    ...mapActions("plan", ["getSlots", "setSelectedSlot"]),
    saveMap() {
      if (
        this.mapTypes.length &&
        this.selectedSlot &&
        this.selectedSlot.slots &&
        this.selectedSlot.slots.length
      ) {
        const postObject = {
          level_id: this.selectedLevel.id,
          period_id: this.periodId,
          datetime_from: this.selectedSlot.slots[0].datetime_from,
          datetime_to: this.selectedSlot.slots.at(-1).datetime_to,
          ordering: this.maps && this.maps.length ? this.maps.length + 1 : 1,
          type_ids: this.mapTypes.map(el => el.id),
          type: this.selectedSlot.type
        };
        this.createMap(postObject).then(() => {
          this.setSelectedSlot(null);
          this.$router.push({
            name: "r_one-click-map"
          });
        });
      }
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    SelectSlots
  }
};
</script>
