<template>
  <section class="select-slots icon-pla1" v-if="slots && slots.data">
    <ul v-if="slots.data.length > 1" class="select-slots-buttons">
      <li
        class="slot-group-selector"
        v-for="slotData in slots.data"
        :key="slotData.type"
      >
        <button
          class="select-slot-group"
          :class="{ active: slotData.default }"
          @click="selectSlot(slotData.type)"
        >
          <icon :icon="displaySlotIcon(slotData.type)" />
        </button>
      </li>
    </ul>
    <ul class="slot-lists">
      <li
        class="list-wrapper"
        v-for="slotData in slots.data"
        :key="slotData.type"
        :class="{ selected: slotData.default }"
      >
        <ul
          class="slot-list"
          :class="{
            quatro: ['HALF_HOUR', 'QUARTER_HOUR'].includes(slotData.type)
          }"
        >
          <li
            class="slot"
            v-for="(innerSlot, idx) in slotData.slots"
            :key="idx"
          >
            <button
              class="slot-select-button"
              :disabled="!innerSlot.available"
              :class="{
                selected:
                  setSlotSelectedClass(innerSlot) &&
                  selectedSlot.type === slotData.type
              }"
              @click="selectSlotForDeclaration(innerSlot, slotData.type)"
              :ref="innerSlot.available ? `${slotData.type}${idx}` : ''"
            >
              <span
                :class="
                  `occupancy ${displayOccupancyClass(
                    innerSlot.occupancy_percentage
                  )}`
                "
                v-if="['HALF_HOUR', 'QUARTER_HOUR'].includes(slotData.type)"
                >{{ innerSlot.occupancy }}</span
              >
              <span class="duration" v-if="slotData.type === 'HALF_DAY'">{{
                idx === 0
                  ? displayLabelName("plan", "plan", "morning")
                  : displayLabelName("plan", "plan", "afternoon")
              }}</span>
              <span class="duration" v-else-if="slotData.type === 'HOUR'">
                <time class="hour" :datetime="innerSlot.from">{{
                  displayDate(innerSlot.from, globalTimeFormat || "HH:mm")
                }}</time>
                -
                <time class="hour" :datetime="innerSlot.to">{{
                  displayDate(innerSlot.to, globalTimeFormat || "HH:mm")
                }}</time>
              </span>
              <span
                class="duration"
                v-else-if="
                  ['QUARTER_HOUR', 'HALF_HOUR'].includes(slotData.type)
                "
              >
                <time class="hour" :datetime="innerSlot.from">{{
                  displayDate(innerSlot.from, globalTimeFormat || "HH:mm")
                }}</time>
                <time class="hour" :datetime="innerSlot.to">{{
                  displayDate(innerSlot.to, globalTimeFormat || "HH:mm")
                }}</time>
              </span>
              <span class="duration" v-else>{{
                displayLabelName("plan", "plan", "day")
              }}</span>
              <span
                class="meta"
                v-if="['DAY', 'HALF_DAY', 'HOUR'].includes(slotData.type)"
              >
                <time class="date" :datetime="innerSlot.to">{{
                  displayDate(innerSlot.to, globalDateFormat || "DD MMMM YYYY")
                }}</time>
                <span
                  :class="
                    `occupancy ${displayOccupancyClass(
                      innerSlot.occupancy_percentage
                    )}`
                  "
                  >{{
                    showPercentage ? `${innerSlot.occupancy_percentage}%` : ""
                  }}</span
                >
              </span>
            </button>
          </li>
        </ul>
      </li>
    </ul>
  </section>
</template>

<script>
import dayjs from "dayjs";
import { mapActions, mapState, mapGetters } from "vuex";
import { displayDateWithTimezone, formatDate } from "@/services/helpers";
import { DateTime } from "luxon";

export default {
  name: "SelectSlots",
  data() {
    return {
      slot: ""
    };
  },
  computed: {
    ...mapState("companyPreferences", ["preferences"]),
    ...mapState("plan", ["selectedSlot", "resourcesLimit", "selectedResource"]),
    ...mapState("timezone", ["timezones"]),
    ...mapGetters("settings", ["globalDateFormat", "globalTimeFormat"])
  },
  watch: {
    slots: {
      immediate: true,
      handler(val) {
        if (val && val.data) {
          if (!this.slot) {
            this.selectSlot();
          } else if (
            this.slot &&
            this.slots.data &&
            this.slots.data.find(el => el.type === this.slot) &&
            !this.slots.data.find(el => el.type === this.slot).default
          ) {
            this.selectSlot(this.slot);
          }
        }
      }
    },
    preselectedSlot: {
      immediate: true,
      handler(val) {
        this.slot = val;
      }
    },
    slot: {
      immediate: true
    }
  },
  methods: {
    ...mapActions("plan", ["setSelectedSlot"]),
    selectSlot(slot) {
      if (slot) {
        this.slot = slot;
        const newSlots = this.slots.data.map(el =>
          el.type === slot
            ? { ...el, default: true }
            : { ...el, default: false }
        );
        this.$store.commit(
          "plan/setSlotsData",
          { data: newSlots },
          { root: true }
        );
      } else {
        // If there is no default slot, take first one from the list
        this.slot = this.slots.data.find(el => el.default);
        if (this.slot == undefined) {
          this.slot = this.slots.data[0].type;
        } else {
          this.slot = this.slot.type;
        }
      }
    },
    displaySlotIcon(type) {
      if (type === "DAY") {
        return "#cx-pla1-all-day-32x32";
      } else if (type === "HALF_DAY") {
        return "#cx-pla1-half-day-32x32";
      } else if (type === "HALF_HOUR") {
        return "#cx-pla1-30-min-32x32";
      } else if (type === "HOUR") {
        return "#cx-pla1-hours-32x32";
      } else if (type === "QUARTER_HOUR") {
        return "#cx-pla1-15-min-32x32";
      }
    },
    displaySlotTitle(type) {
      return type.replace(/_/g, " ");
    },
    formatDate(date, format) {
      return formatDate(date, format);
    },
    displayOccupancyClass(occupancy_percentage) {
      if (!this.showPercentage) return "";

      let partiallyBusy = 60;
      let busy = 90;

      let partiallyBusyPreference = this.preferences.find(
        el => el.preference === "PARTIALLY_BUSY_PERCENTAGE"
      );
      let busyPreference = this.preferences.find(
        el => el.preference === "BUSY_PERCENTAGE"
      );
      if (
        partiallyBusyPreference &&
        partiallyBusyPreference.preference_values &&
        partiallyBusyPreference.preference_values.length &&
        busyPreference &&
        busyPreference.preference_values &&
        busyPreference.preference_values.length
      ) {
        partiallyBusy = partiallyBusyPreference.preference_values[0].value;
        busy = busyPreference.preference_values[0].value;
      }

      if (occupancy_percentage >= busy) {
        return "high";
      } else if (
        occupancy_percentage > partiallyBusy &&
        occupancy_percentage < busy
      ) {
        return "moderate";
      } else {
        return "low";
      }
    },
    setSlotSelectedClass(slot) {
      if (
        this.selectedSlot &&
        this.selectedSlot.slots &&
        this.selectedSlot.slots.length
      ) {
        if (!this.selectedSlot.simpleSlots) {
          return this.selectedSlot.slots.filter(
            item => item.datetime_from === slot.from
          ).length;
        } else {
          return (
            DateTime.fromISO(slot.from) >=
              DateTime.fromISO(this.selectedSlot.slots[0].datetime_from) &&
            DateTime.fromISO(slot.to) <=
              DateTime.fromISO(this.selectedSlot.slots[0].datetime_to)
          );
        }
      }
      return false;
    },
    slotIsAlreadySelected(slot) {
      if (
        this.selectedSlot &&
        this.selectedSlot.slots &&
        this.selectedSlot.slots.length
      ) {
        return this.selectedSlot.slots.filter(
          item => item.datetime_from === slot.from
        ).length;
      }
      return false;
    },
    selectSlotForDeclaration(slot, type) {
      if (this.$route.path.indexOf("resources/slots") !== -1) {
        this.$router.push({
          name: "r_plan-resources-slots"
        });
      }

      let data = {
        type
      };
      if (!slot.available) {
        return;
      }

      if (!this.selectedSlot) {
        data.occupancy_percentage = slot.occupancy_percentage;
        data.slots = [
          {
            datetime_from: slot.from,
            datetime_to: slot.to
          }
        ];
        this.setSelectedSlot(data);
      } else {
        const firstSlot = this.selectedSlot.slots[0];
        // if type is different or slot is before selected slot
        // replace selected slot with current one
        if (
          this.selectedSlot.type !== type ||
          this.slotIsBefore(firstSlot, slot)
        ) {
          this.setSelectedSlot({
            ...data,
            slots: [
              {
                datetime_from: slot.from,
                datetime_to: slot.to,
                occupancy_percentage: slot.occupancy_percentage
              }
            ],
            occupancy_percentage: slot.occupancy_percentage
          });
        } else {
          // get all the slots of this type
          const slotsOfType = this.slots.data.filter(
            item => item.type === type
          );
          // find indexes of first selected and second selected slot
          const firstSlotIndex = this.findSlotIndex(firstSlot, type);
          let secondSlotIndex = this.findSlotIndex(slot, type);

          // if slot is already selected
          if (this.slotIsAlreadySelected(slot)) {
            // if is only slot empty an array
            if (
              this.selectedSlot.slots.length === 1 ||
              firstSlotIndex === secondSlotIndex
            ) {
              this.setSelectedSlot(null);
            }
            // if slot is already selected and somewhere in the middle
            // select all slots before that slot
            secondSlotIndex--;
          }

          const slots = [];
          for (let i = firstSlotIndex; i <= secondSlotIndex; i++) {
            // ako je i !== firstSlotIndex

            if (
              i === firstSlotIndex ||
              (i !== firstSlotIndex &&
                slotsOfType[0].slots[i].from === slotsOfType[0].slots[i - 1].to)
            ) {
              // overwrite occupancy if it's higher than current occupancy
              if (
                slotsOfType[0].slots[i].occupancy_percentage >=
                  this.selectedSlot.occupancy_percentage ||
                slotsOfType[0].slots[i].occupancy_percentage >=
                  data.occupancy_percentage
              ) {
                data.occupancy_percentage =
                  slotsOfType[0].slots[i].occupancy_percentage;
              }

              slots.push({
                datetime_from: slotsOfType[0].slots[i].from,
                datetime_to: slotsOfType[0].slots[i].to
              });
              data.slots = slots;
              this.setSelectedSlot(data);
            }
          }
        }
      }
    },
    findSlotIndex(slot, type) {
      const slots = this.slots.data.filter(item => item.type === type);
      if (slot.datetime_from) {
        return slots[0].slots.findIndex(
          item => item.from === slot.datetime_from
        );
      }
      return slots[0].slots.findIndex(item => item.from === slot.from);
    },
    slotIsBefore(slot1, slot2) {
      /*
      available: true
      capacity: 0
      from: "2021-04-23T08:00:00"
      occupancy: 0
      occupancy_percentage: 100
      to: "2021-04-23T12:00:00"
       */
      if (slot1.datetime_from) {
        return dayjs(slot2.from).isBefore(dayjs(slot1.datetime_from));
      } else {
        return dayjs(slot2.from).isBefore(dayjs(slot1.from));
      }
    },
    slotIsEqual(slot1, slot2) {
      if (slot1.datetime_from) {
        return dayjs(slot2.from).isSame(dayjs(slot1.datetime_from));
      } else {
        return dayjs(slot2.from).isSame(dayjs(slot1.from));
      }
    },
    displayDate(date, format) {
      if (this.timezones && this.timezones.data) {
        return displayDateWithTimezone({
          date,
          format,
          timezones: this.timezones
        });
      }
      return "";
    }
  },
  props: {
    slots: {
      required: false
    },
    showPercentage: {
      type: Boolean,
      required: false
    },
    preselectedSlot: {
      required: false,
      type: String
    }
  }
};
</script>
